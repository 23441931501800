$dropdown: '.ucmp-button__has_dropdown'
$navbar: '.ucmp-navbar'

#{$dropdown}
  position: relative

.ucmp-header__main
  margin: 0 !important

.ucmp-header__container
  padding: 0 4px 0 16px !important
  height: initial !important

.ucmp-header__navigation
  .ucmp-button
    &:hover
      background-color: #26890d
      box-shadow: inset 0 0 0 1px #26890d
    &__content
      padding: 7px 10px

.ucmp-navbar__dropdown-menu,
.ucmp-navbar__dropdown-submenu
  position: relative
  display: none

.ucmp-navbar__dropdown-menu
  position: absolute
  top: calc(100% - 2px)
  left: 0
  z-index: 1000
  // min-width: 160px
  padding: 5px 0
  margin: 2px 0 0
  font-size: 13px
  text-align: left
  list-style: none
  background-color: #000
  color: #fff
  background-clip: padding-box
  border-radius: 0
  box-shadow: 0 6px 12px rgba(0, 0, 0, .175)
  // overflow: scroll
  // max-height: 500px

.ucmp-navbar__dropdown-menu>li>span
  display: block
  padding: 8px 16px
  clear: both
  font-weight: normal
  line-height: 1.42857143
  color: #fff
  white-space: nowrap

  &:hover,
  &:focus
    color: #fff
    text-decoration: none
    background-color: #26890d

.ucmp-navbar__dropdown-menu>li
  &.disabled
    pointer-events: none
    cursor: not-allowed
    span
      color: #9d9d9d

.ucmp-button__has_dropdown:hover>.ucmp-navbar__dropdown-menu
  display: block
  &>.ucmp-navbar__dropdown-submenu
    display: block

.ucmp-navbar__dropdown-submenu
  & > .ucmp-navbar__dropdown-menu
    top: -7px
    left: 100%

.ucmp-navbar__dropdown-submenu
  &:hover,
  &:focus
    display: block
    & > .ucmp-navbar__dropdown-menu
      display: block

.ucmp-button__disabled
  pointer-events: none
  cursor: not-allowed
  color: #9d9d9d !important

.ucmp-navbar__dropdown-menu>.ucmp-navbar__dropdown-submenu:not(.disabled)>.ucmp-navbar__dropdown-item
  display: flex
  justify-content: space-between
  align-items: center
  width: 100%
  &::after
    content: ''
    height: 0
    width: 0
    margin-left: 16px
    border-top: 5px solid transparent
    border-bottom: 5px solid transparent
    border-left: 5px solid #fff

.ucmp-header__navigation .ucmp-button__content
  padding: 0 10px !important

.ucmp-button__text
  font-size: 13px !important

.ucmp-header__project_name
  font-size: 13px !important

.ucmp-header-sdlc
  overflow-y: auto
  max-height: 70vh
  overflow-x: hidden