.main
  display: flex
  align-items: center
  min-height: calc(100vh - 64px)
  background: #000

.UcmpInput
  label
    input
      margin-top: 4px
      display: block
      width: 100%