.container
  position: relative
  height: 100%

.chatbox
  display: flex
  flex-direction: column
  flex-wrap: nowrap
  border-radius: 10px
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.5)
  height: 100%

$top-bar-class: '.top-bar'

#{$top-bar-class}
  display: flex
  flex-direction: row
  align-content: center
  align-items: center
  flex-wrap: wrap
  gap: 8px
  width: 100%
  height: 48px
  background: #000
  border-radius: 10px 10px 0 0
  img
    margin-left: 8px

#{$top-bar-class}-heading
  font-size: 16px
  font-weight: 700

#{$top-bar-class}-actions
  margin-left: auto

.badge
  background-color: #fff
  color: #000
  padding: 2px
  text-align: center
  border-radius: 5px
  font-size: 12px
  font-weight: bold
  margin-bottom: 12px

.middle
  display: flex
  flex-direction: column
  background-color: #fff
  width: 100%
  opacity: 0.85
  top: 48px
  overflow: auto
  flex-grow: 1

.chat-infolist
  li
    strong
      font-size: 13px !important

.chat-spacer
  display: flex
  flex-grow: 1

.incoming, .outgoing
  display: flex
  flex-direction: column
  flex-wrap: wrap
  align-items: flex-start
  width: 80%
  border-radius: 4px
  font-size: 13px
  padding: 6px
  margin-top: 4px
  margin-bottom: 4px
  margin-left: 8px
  margin-right: 8px
  .time
    margin-left: auto
    white-space: nowrap
    font-size: 10px

.outgoing
  margin-left: auto
  background: #000
  color: white

.incoming
  background: #D0D0CE

$bottom-bar-class: '.bottom-bar'

#{$bottom-bar-class}
  display: flex
  width: 100%
  height: 55px
  bottom: 0
  background: '#000'
  border-radius: 0 0 10px 10px
  border-top: 1px solid #D0D0CE

#{$bottom-bar-class}-chat
  display: flex
  width: 100%

.chat-textbox
  padding: 8px
  border: 0
  background: white
  color: black
  flex-grow: 1
  &:focus, &:focus-visible
    outline: none

.send-button
  height: 36px
  width: 36px
  border: 0
  border-radius: 5555px
  background-color: #000
  color: #fff
  margin: 8px
  margin-left: 0

.send-button-desibled
  height: 36px
  width: 36px
  border: 0
  border-radius: 5555px
  background-color: #808080
  color: #fff
  margin: 8px
  margin-left: 0

ol, ul
  margin: 0
  padding-inline-start: 20px
  *
    font-size: 13px

.incoming, .outgoing
  p, h1, h2, h3, h4, h5, h6, ul
    margin: 0
    margin-bottom: 4px

  h1, h2, h3, h4, h5, h6
    line-height: 1.35

  h6
    font-size: 10px

  h5
    font-size: 11px

  h4
    font-size: 13px

  h3
    font-size: 14px

  h2
    font-size: 16px

  h1
    font-size: 18px



.button-download-icon
  &:hover 
    color: #26890d
    cursor: pointer