// @import url('https://fonts.googleapis.com/css?family=Open+Sans')
@import "ui-components-marketplace/dist/style.css"

body
  margin: 0
  font-family: 'Open Sans', Verdana, Helvetica, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

.MuiTabs-root
  min-height: 32px !important
  .MuiButtonBase-root
    padding: 4px 12px
    min-height: 32px
    height: 32px
  .MuiTouchRipple-root
    min-height: 32px
