.container
  position: relative

.flash_message_container
    

.flash_message
  height: 30px
  width: 350px
  position: absolute
  left: 50%
  transform: translateX(-50%)
  top: -20px
  opacity: 1
  text-align: center
  border-radius: 4px
  border: 1px solid #fff
  display: flex
  justify-content: center
  align-items: center
  gap: 4px
  color: #fff
  box-shadow: 0px 2px 4px hsl(0 0% 0% / 25%)
  animation: demo 7s infinite

@keyframes demo
  0%
    background: #26890d
  22%
    background: #005587
  77%
    background: #007cb0
  100%
    background-color: #da291c

