.chatbot-popup-icon
  position: fixed
  bottom: 60px
  right: 20px
  z-index: 99

.chat-modal
  position: fixed
  bottom: 60px
  right: 20px
  border-radius: 0
  z-index: 99999
  background-color: white
  width: 25vw
  height:488px
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05)

.new-chat-modal
  position: fixed
  bottom: 60px
  right: 20px
  z-index: 9999
  background-color: white
  width: 28vw
  min-width: 360px
  height: 480px
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.07)
  border-radius: 10px
  overflow: hidden
  &:focus-visible
    outline: none
