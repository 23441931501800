.tabler-icon
  &:hover
    pointer-events: cursor

.button-download-icon
  &:hover 
    color: #26890d
    cursor: pointer


.tag-custom-class
  > p
    font-size: 12px
    padding: 2px 3px
