$flip-timing: 1s
.flip-card 
  //background-color: transparent
  //width: 300px
  width:93%
  height: 152px
  //border: 1px solid #f1f1f1
  perspective: 1000px 
  //cursor:pointer


/* This container is needed to position the front and back side */
.flip-card-inner 
  //position: relative
  width: 100%
  height: 100%
  //text-align: center
  transition: transform #{$flip-timing}
  transform-style: preserve-3d


/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner
  transition: transform #{$flip-timing}
  transform: rotateY(180deg)
  transition-delay: 1s



/* Position the front and back side */
.flip-card-front, .flip-card-back 
  position: absolute
  width: 93%
  height: 100%
  -webkit-backface-visibility: hidden /* Safari */
  backface-visibility: hidden


/* Style the front side (fallback if image is missing) */
.flip-card-front 
  //background-color: #bbb
 
  color: black


/* Style the back side */
.flip-card-back 
  height:152
  width:100%
  //cursor:pointer
  
  boxShadow: 1  
  
  borderRadius: 0
  color: black
  transition: transform #{$flip-timing}
  transform: rotateY(180deg)
